



















































































import { Component, Mixins } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import ProgressBar from 'vuejs-progress-bar'
import Billing from '@/mixins/Billing'

@Component({
  components: { ProgressBar },
})
export default class Rewards extends Mixins(Billing) {
  @Getter networkType!: string

  options = {
    text: {
      color: '#FFFFFF',
      shadowEnable: true,
      shadowColor: '#000000',
      fontSize: 8,
      fontFamily: 'Helvetica',
      dynamicPosition: false,
      hideText: false,
    },
    progress: {
      color: '#de1a23',
      backgroundColor: 'grey',
      inverted: false,
    },
    layout: {
      height: 10,
      verticalTextAlign: 61,
      horizontalTextAlign: 43,
      zeroOffset: 0,
      strokeWidth: 30,
      progressPadding: 0,
      type: 'line',
    },
  }

  loadIframe() {
    //@ts-ignore
    Tally.loadEmbeds()
  }

  mounted() {}

  get isOfferApplicable() {
    return ['YOUTUBE_PRO'].includes(this.networkType)
  }

  get progressBarValue() {
    return this.invoiceData.total ? parseFloat((this.invoiceData.total / 240).toFixed(2)) : 0
  }

  get shareURL() {
    return 'https://adorilabs.com'
  }

  get shareText() {
    return 'Check out Adori!'
  }

  get shareHashtags() {
    return '#textToSpeech #textToVideo #voiceover #audio #video'
  }

  postToTwitter() {
    let twitterUrl = `https://twitter.com/intent/tweet?text=${this.shareText}\
&url=${this.shareURL}&hashtags=${encodeURIComponent(this.shareHashtags)}&via=adorilabs`
    window.open(twitterUrl, '_blank')
  }
  postToLinkedIn() {
    let linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/\
?url=${this.shareURL}`
    window.open(linkedinUrl, '_blank')
  }
  postToTrustpilot() {
    let trustpilotUrl = `https://www.trustpilot.com/review/adorilabs.com`
    window.open(trustpilotUrl, '_blank')
  }

  postToG2C() {
    let g2cUrl = `https://www.g2.com/products/adori-video/reviews`
    window.open(g2cUrl, '_blank')
  }
}
